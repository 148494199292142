import { IEditableAttribute, IEditableElement } from "../../core/EditableAttributes";

const bodyObserverCallback = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
        const element = entry.target as HTMLElement;
        element.style.setProperty("--body-ratio", `${entry.intersectionRatio}`);
        if (entry.isIntersecting) {
            const h = entry.boundingClientRect.height;
            element.style.setProperty("--body-height", `${h}`);
        }
    }
};

const aboveBodyObserverCallback = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
        const element = entry.target as HTMLElement;
        element.style.setProperty("--above-body-ratio", `${entry.intersectionRatio}`);
        if (entry.isIntersecting) {
            const h = entry.boundingClientRect.height;
            element.style.setProperty("--above-body-height", `${h}`);
            element.setAttribute("above-body", "yes");
        } else {
            element.removeAttribute("above-body");
        }
    }
};

const belowBodyObserverCallback = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
        const element = entry.target as HTMLElement;
        element.style.setProperty("--below-body-ratio", `${entry.intersectionRatio}`);
        if (entry.isIntersecting) {
            const h = entry.boundingClientRect.height;
            element.style.setProperty("--below-body-height", `${h}`);
            element.setAttribute("below-body", "yes");
        } else {
            element.removeAttribute("below-body");
        }
    }
};

class BodyObserver {

    body: IntersectionObserver;
    beforeBody: IntersectionObserver;
    afterBody: IntersectionObserver;

    bodyObserver: IntersectionObserver;
    beforeBodyObserver: IntersectionObserver;
    // afterBodyObserver: IntersectionObserver;
    timer: any;

    prepare() {
        Object.defineProperty(this, "prepare", { value: () => void 0});

        const threshold = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
        // const threshold = 0;

        this.bodyObserver = new IntersectionObserver(bodyObserverCallback, {
            threshold,
        });
        
        this.beforeBodyObserver = new IntersectionObserver(aboveBodyObserverCallback, {
            threshold,
            rootMargin: "100% 0px -100% 0px"
        });
        // this.afterBodyObserver = new IntersectionObserver(belowBodyObserverCallback, {
        //     threshold,
        //     rootMargin: "-95% 0px 95% 0px"
        // });
    }

    observe(e) {
        this.prepare();
        this.bodyObserver.observe(e);
        // this.afterBodyObserver.observe(e);
        this.beforeBodyObserver.observe(e);
        this.pushCallback();
    }

    unobserve(e) {
        this.bodyObserver.unobserve(e);
        // this.afterBodyObserver.unobserve(e);
        this.beforeBodyObserver.unobserve(e);
    }
    pushCallback() {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => {
            this.timer = void 0;
            bodyObserverCallback(this.bodyObserver.takeRecords());
            // aboveBodyObserverCallback(this.beforeBodyObserver.takeRecords());
            // belowBodyObserverCallback(this.afterBodyObserver.takeRecords());
        }, 100);
    }

}

let bodyObserver = new BodyObserver();

export default class ScrollAware extends HTMLElement implements IEditableElement {

    get editableAttributes(): IEditableAttribute[] {
        return [
            {
                name: "animate-distance",
                type: "enum",
                values: [
                    "move-away",
                ]
            },
            {
                name: "animate-filter",
                type: "enum",
                values: [
                    "blur",
                ]
            },
            {
                name: "animate-opacity",
                type: "enum",
                values: [
                    "fade-away",
                ]
            },

        ]
    }

    connectedCallback() {
        if (this.isContentEditable) {
            return;
        }
        bodyObserver.observe(this);
    }

    disconnectedCallback() {
        if (this.isContentEditable) {
            return;
        }
        bodyObserver.unobserve(this);
    }

}

customElements.define("scroll-aware", ScrollAware);