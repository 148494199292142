import { IEditableAttribute } from "../../core/EditableAttributes";
import HtmlControl from "../../core/HtmlControl";
import sanitize, { sanitizeDom } from "../../services/sanitize/sanitize";


export  default class InlineSvg extends HtmlControl {

    root: ShadowRoot;

    loading: any;
    loadingSrc: any;

    abortController: AbortController;

    static observedAttributes = ["src", "mode"];

    get editableAttributes(): IEditableAttribute[] {
        return [
            { name: "src", type: "image" },
            { name: "mode", type: "enum", values: [
                "shadow",
                "dom"
            ] }
        ];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "src":
            case "mode":
                this.updateImage().catch(console.error);
                break;
        }
    }

    prepare() {
        this.updateImage().catch(console.error);
    }

    async updateImage() {

        const src = this.getAttribute("src");

        if(!src) {
            return;
        }

        if (src === this.loadingSrc) {
            return;
        }
        this.loadingSrc = src;

        this.abortController?.abort();

        const a = this.abortController = new AbortController();
        const signal = a.signal;

        this.innerHTML = "";
        this.removeAttribute("status");

        try {
            const rs = await fetch(src, { signal });
            if (rs.status >= 400) {
                const text = await rs.text();
                const er = new Error(`$Http Error ${rs.status}`);
                (er as any).response = { status: rs.status, text };
                throw er;
            }
            let svgText = await rs.text();

            svgText = await this.sanitize(svgText, signal);

            if (signal.aborted) {
                return;
            }

            if(this.getAttribute("mode") === "dom") {
                await this.updateDomImage(svgText);
                this.setAttribute("status", "loaded");
                this.loadingSrc = null;
                return;
            }
            await this.updateShadowImage(svgText);
            this.setAttribute("status", "loaded");
            this.loadingSrc = null;
        } catch (error) {
            this.setAttribute("status", "failed");
            this.loadingSrc = null;
            const { response } = error;
            if (response) {
                console.error({ error, response })
                window.dispatchEvent(new ErrorEvent("error", { error }));
            } else {
              window.dispatchEvent(new ErrorEvent("error", { error }));
              console.error(error);
            }
        }
    }

    async sanitize(svgText: string, signal)  {
        if (document.body.hasAttribute("editing") || this.isContentEditable) {
            return await sanitizeDom(svgText, "image/svg+xml");
        }
        return await sanitize(svgText, "image/svg+xml");
    }

    async updateDomImage(svgText: string) {

        this.innerHTML = svgText

    }

    async updateShadowImage(svgText: string) {
        this.root ??= this.attachShadow({ mode: "closed"});
        this.root.innerHTML = svgText;
    }

}

customElements.define("inline-svg", InlineSvg);