import { ComponentHelper } from "../../core/ComponentHelper";

export const reveal = new IntersectionObserver((entries) => {

    for (const iterator of entries) {
        if(iterator.isIntersecting) {
            reveal.unobserve(iterator.target);
            iterator.target.setAttribute("data-revealed", "true");
        }
    }

});

ComponentHelper.waitForReady().then(() => {
    const all = document.querySelectorAll("[data-on-reveal]");
    for (let index = 0; index < all.length; index++) {
        const element = all[index];
        if (element.hasAttribute("data-revealed")) {
            continue;
        }
        reveal.observe(element);
    }
});